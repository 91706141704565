import React from 'react';

import AppRoute from 'client/components/app-route';
import {ROUTES} from 'client/routes';

import Page from './components/page';

const {CITIES} = ROUTES;

export default [
    <AppRoute path={CITIES.INDEX} component={Page} key={CITIES.INDEX} exact/>,
];
