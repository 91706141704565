import {Indexed} from 'utils';

import config from 'client/config/config';

const {NODE_ENV} = process.env;

export const OPTIONS: Indexed<unknown> = {
    webvisor: true,
};

export const VERSION: '1' | '2' = '2';
export const ACCOUNTS: number[] = (function () {
    switch (NODE_ENV) {
        case config.__PROD__:
            return [
                12208345, // Общий таксишный счетчик
                70519114, // Счетчик проекта
            ];
        case config.__TEST__:
        default:
            return [];
    }
})();
