import React, {useCallback, useState} from 'react';

import Header from 'client/components/header';
import Layout from 'client/components/layout/Layout';
import {useI18N} from 'client/utils/hooks/useI18N';

import Cities from '../cities';
import {Props} from './types';

import styles from './Page.scss';

const Page: Props = () => {
    const [search, setSearch] = useState('');
    const onSearch = useCallback(e => {
        setSearch(e.target.value);
    }, []);
    const i18n = useI18N();

    return (
        <>
            <Header/>
            <Layout className={styles.layout}>
                <div className={styles.wrapper}>
                    <section className={styles.titleWrapper}>
                        <h1 className={styles.title}>
                            {i18n('CITY_TITLE')}
                        </h1>
                        <span className={styles.description}>
                            {i18n('CITY_DESCRIPTION')}
                        </span>
                    </section>
                    <input
                        onChange={onSearch}
                        autoComplete="off"
                        className={styles.input}
                        placeholder={i18n('SEARCH_PLACEHOLDER')}
                    />
                    <Cities search={search}/>
                </div>
            </Layout>
        </>
    );
};
export default Page;
