import {createSelector} from 'reselect';

import {CommonStore} from 'client/utils/infrastructure/store';

export const pathnameSelector = createSelector(
    (state: CommonStore) => state.router.location.pathname,
    path => path,
);

export const searchParamsSelector = createSelector(
    (state: CommonStore) => state.router.location.search,
    search => search,
);
