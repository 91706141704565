import qs from 'qs';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import Button from 'client/components/button/components/Button';
import Header from 'client/components/header';
import Layout from 'client/components/layout';
import {makeCityByIdSelector, makeIsSelfEmployeeCountrySelector} from 'client/selectors/cities';
import {hasHiddenParksSelector, hasSelfemployeeParksSelector, parksSelector} from 'client/selectors/parks';
import {parksService} from 'client/services/ParksService';
import {RentType} from 'client/types/parks';
import {useI18N} from 'client/utils/hooks/useI18N';

import Parks from '../parks';
import RentModal from '../rent-modal';
import SelfemployeeModal from '../selfemployee-modal';
import {Props} from './types';

import styles from './Page.scss';

const Page: Props = ({match: {params: {city: id}}, location: {search}}) => {
    const {type: rentType}: {type?: RentType} = qs.parse(search, {ignoreQueryPrefix: true});

    const [showAll, setShowAll] = useState(false);
    const [rentModalVisible, setRentModalVisible] = useState(false);
    const [selfemployeeModalVisible, setSelfemployeeModalVisible] = useState(false);

    const city = useSelector(makeCityByIdSelector(id));
    const parks = useSelector(parksSelector);
    const hasHiddenParks = useSelector(hasHiddenParksSelector);
    const hasSelfemployeeParks = useSelector(hasSelfemployeeParksSelector);
    const isSelfemployeeCountry = useSelector(makeIsSelfEmployeeCountrySelector(city?.id));

    const toggleShowAll = useCallback(() => {
        setShowAll(!showAll);
    }, [showAll]);
    const toggleRentModalVisible = useCallback(() => {
        setRentModalVisible(!rentModalVisible);
    }, [rentModalVisible]);
    const toggleSelfemployeeModalVisible = useCallback(() => {
        setSelfemployeeModalVisible(!selfemployeeModalVisible);
    }, [selfemployeeModalVisible]);

    useEffect(() => {
        if (!rentType) {
            toggleRentModalVisible();
        }
    }, []);

    useEffect(() => {
        parksService.getParks(rentType!, id!);
    }, [rentType]);

    const i18n = useI18N();

    const content = useMemo(() => {
        if (parks.length === 0) {
            return <span className={styles.placeholder}>{i18n('EMPTY_PARKS_PLACEHOLDER')}</span>;
        }

        return (
            <>
                <div className={styles.banner}>
                    <section className={styles.bannerText}>
                        <h1 className={styles.title}>
                            {i18n('CALL_TITLE')}
                        </h1>
                        <span className={styles.description}>
                            {i18n('CALL_DESCRIPTION')}
                        </span>
                    </section>
                </div>
                {hasSelfemployeeParks && isSelfemployeeCountry &&
                    <div className={styles.selfemployed}>
                        <section className={styles.selfemployedText}>
                            <h1 className={styles.selfemployedTitle}>
                                {i18n('SELFEMPLOYEE_TITLE')}
                            </h1>
                            <span className={styles.description}>
                                {i18n('SELFEMPLOYEE_DESCRIPTION')}
                            </span>
                            <Button
                                theme="taxi"
                                variant="outlined"
                                className={styles.selfemployedBtn}
                                onClick={toggleSelfemployeeModalVisible}
                            >
                                {i18n('SELFEMPLOYEE_MORE_INFO')}
                            </Button>
                        </section>
                    </div>
                }
                <div className={styles.wrapper}>
                    <Parks showAll={showAll} rentType={rentType} parks={parks}/>
                    {hasHiddenParks && !showAll &&
                        <Button className={styles.showAllBtn} theme="light" variant="outlined" onClick={toggleShowAll}>
                            {i18n('SHOW_ALL_PARKS')}
                        </Button>
                    }
                </div>
            </>
        );
    }, [i18n, hasSelfemployeeParks, isSelfemployeeCountry, parks, hasHiddenParks, showAll]);

    return (
        <>
            <Header city={city?.label}/>
            <Layout className={styles.layout}>
                {content}
            </Layout>
            {rentModalVisible && <RentModal onClose={toggleRentModalVisible}/>}
            {selfemployeeModalVisible && <SelfemployeeModal type={rentType} handleClose={toggleSelfemployeeModalVisible}/>}
        </>
    );
};
export default Page;
