import React from 'react';

import ErrorBoundary from 'client/components/error-boundry';
import isServer from 'client/utils/isServerEnvCheker';

import Wrapper from '../wrapper';
import {Props} from './types';

const {__PROD__} = process.env;

if (!isServer) {
    import('client/utils/logger')
        .then(({initErrorLogger}) => {
            initErrorLogger();
        });
}

const Page: Props = () => {
    return __PROD__
        ? (
            <ErrorBoundary>
                <Wrapper/>
            </ErrorBoundary>
        )
        : <Wrapper/>;
};
export default Page;
