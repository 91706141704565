import cn from 'classnames';
import React, {useCallback, useMemo} from 'react';

import {bound as commonActions} from 'client/actions';
import arrowLeft from 'client/assets/images/arrow-left.svg';
import closeIcon from 'client/assets/images/closeIcon.svg';
import {useEscapeKeyListener} from 'client/utils/hooks/useEscapeKeyListener';

import Spinner from '../spinner';
import {Props} from './types';

import styles from './Modal.scss';

const paranjaClassName = styles.paranja;

const Modal: Props = ({
    handleClose,
    isVisible,
    title,
    children,
    back,
    classNames,
    withCloseBtn = true,
    isPending,
}) => {
    const onCloseModal = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((e.target as HTMLElement).className === paranjaClassName) {
            handleClose?.();
        }
    }, [handleClose]);

    useEscapeKeyListener(window, onCloseModal);

    const onBackHandler = useCallback(() => {
        if (back?.customHandler) {
            back?.customHandler();
        }

        if (back?.to) {
            commonActions.router.push(back.to);
        }
    }, [back]);

    const header = useMemo(() => (
        <div className={cn(styles.header, classNames?.header)}>
            {withCloseBtn && (
                <img
                    src={closeIcon}
                    alt="close-icon"
                    className={styles.closeBtn}
                    onClick={handleClose}
                />
            )}

            {title && <h2>{title}</h2>}

            {back && (
                <img
                    src={arrowLeft}
                    alt="url-back-icon"
                    className={styles.closeBtn}
                    onClick={onBackHandler}
                />
            )}
        </div>
    ), [title, handleClose]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={cn(styles.modal, classNames?.modal)}>
            <div className={paranjaClassName} onClick={onCloseModal}>
                <div className={cn(styles.box, classNames?.box)}>
                    {header}
                    <div className={cn(styles.body, classNames?.body)}>
                        {isPending
                            ? (
                                <div className={styles.spinnerWrapper}>
                                    <Spinner/>
                                </div>
                            )
                            : children
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
