import React from 'react';

import {bound as commonActions} from 'client/actions';
import Button, {Group} from 'client/components/button';
import NotifyPage from 'client/components/notify-page';
import {ROUTES} from 'client/routes';

import styles from './Page.scss';

function handleRedirect() {
    commonActions.router.push(ROUTES.CITIES.INDEX);
}

export default function Page() {
    return (
        <NotifyPage
            title={(
                <span className={styles.headerText}>
                    Страница не найдена
                </span>
            )}
            description={(
                <div>
                    <h3>404</h3>
                    <Group className={styles.buttonWrapper}>
                        <Button onClick={handleRedirect} size="large">
                            Вернуться на главную
                        </Button>
                    </Group>
                </div>
            )}
        />
    );
}
