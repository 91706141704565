import qs from 'qs';
import {Nil} from 'utils';

import {bound as commonActions} from 'client/actions';
import {langQuerySelector} from 'client/selectors/query';
import {searchParamsSelector} from 'client/selectors/router';
import {
    CommonParams,
} from 'client/types/query';
import {checkLang} from 'client/utils/checkLanguage';
import {getStore} from 'client/utils/infrastructure/store';

export default class QueryService {
    public init = () => {
        const state = getStore();

        const queries = qs.parse(searchParamsSelector(state), {ignoreQueryPrefix: true});

        const currLang = langQuerySelector(state);

        if (!currLang) {
            commonActions.query.common.item.success(this.clearParams<CommonParams>({
                lang: checkLang(queries.lang as Nil<string>),
                region_id: queries.region_id as Nil<string>,
            }));
        }
    };

    private clearParams = <T>(params: Partial<T>): Partial<T> => {
        return Object.keys(params).reduce((result, current) => {
            if (!params[current]) {
                return result;
            }

            return {
                ...result,
                [current]: params[current],
            };
        }, {});
    };

}

export const queryService = new QueryService();
