import React, {useMemo} from 'react';
import ym from 'react-yandex-metrika';

import Button from 'client/components/button/components/Button';
import {ParkGroup, RentType} from 'client/types/parks';
import {useI18N} from 'client/utils/hooks/useI18N';
import triggerLink from 'client/utils/triggerLink';

import {Props} from './types';

import styles from './Parks.scss';

const makeHandlePhoneClick = (phone: string) => () => {
    triggerLink(`tel:${phone.replace(/\s/g, '')}`, {target: '_blank'});
};

const handleOpenGarageLink = (link: string) => () => {
    ym('reachGoal', 'goal_link_to_garage');
    ym('extLink', link);
};

const Parks: Props = ({showAll, rentType, parks}) => {
    const i18n = useI18N();
    const cards = useMemo(() => {
        return parks.reduce<React.ReactNode[]>((acc, park, index) => {
            const {sf_name: name, address, dispatch_phone: phone, link_to_garage_ad, group} = park;
            if (group === ParkGroup.Group3 && !showAll) {
                return acc;
            }

            acc.push((
                <div className={styles.card} key={index}>
                    <span className={styles.name}>«{name}»</span>
                    {group === ParkGroup.Group1 &&
                        <span className={styles.selfemployee}>★ {i18n('WORKS_WITH_SELFEMPLOYEE')}</span>
                    }
                    {link_to_garage_ad && rentType === RentType.Rent && (
                        <>
                            <span className={styles.description}>{i18n('PARK_ADS')}:</span>
                            <a
                                className={styles.link}
                                onClick={handleOpenGarageLink(link_to_garage_ad)}
                                href={link_to_garage_ad}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {link_to_garage_ad}
                            </a>
                        </>
                    )}
                    <span className={styles.description}>{i18n('PARK_ADDRESS')}:</span>
                    <span>{address}</span>
                    <Button theme="primary" className={styles.phoneBtn} onClick={makeHandlePhoneClick(phone)}>
                        {phone}
                    </Button>
                </div>
            ));
            return acc;
        }, []);
    }, [parks, showAll]);

    return (
        <div className={styles.wrapper}>
            {cards}
        </div>
    );
};
export default Parks;
