import React, {useCallback} from 'react';

import percentIcon from 'client/assets/images/percents.svg';
import roubleIcon from 'client/assets/images/rouble.svg';
import starIcon from 'client/assets/images/star.svg';
import taxiIcon from 'client/assets/images/taxi.svg';
import Button from 'client/components/button/components/Button';
import Modal from 'client/components/modal';
import {useI18N} from 'client/utils/hooks/useI18N';
import triggerLink from 'client/utils/triggerLink';

import {SELFEMPLOYEE_LINK} from '../../consts';
import {Props} from './types';

import styles from './SelfemployeeModal.scss';

const modalClassnames = {
    box: styles.box,
    body: styles.body,
    header: styles.header,
};

const SelfemployeeModal: Props = ({handleClose, type}) => {
    const handleMoreInfoClick = useCallback(() => {
        triggerLink(`${SELFEMPLOYEE_LINK}${type}`, {target: '_blank'});
    }, [type]);
    const i18n = useI18N();

    return (
        <Modal
            isVisible={true}
            classNames={modalClassnames}
            handleClose={handleClose}
        >
            <span className={styles.title}>{i18n('SELFEMPLOYEE_BENEFITS')}</span>
            <div className={styles.text}>
                <img src={roubleIcon}/>
                <span>{i18n('SELFEMPLOYEE_INCOME')}</span>
            </div>
            <div className={styles.text}>
                <img src={percentIcon}/>
                <span>{i18n('SELFEMPLOYEE_FEE')}</span>
            </div>
            <div className={styles.text}>
                <img src={starIcon}/>
                <span>{i18n('SELFEMPLOYEE_CREDIT')}</span>
            </div>
            <div className={styles.text}>
                <img src={taxiIcon}/>
                <span>{i18n('SELFEMPLOYEE_ORDERS')}</span>
            </div>
            <Button className={styles.btn} theme="light" variant="outlined" onClick={handleMoreInfoClick}>
                {i18n('BECOME_SELFEMPLOYEE')}
            </Button>
        </Modal>
    );
};

export default SelfemployeeModal;
