import {ConnectedRouter} from 'connected-react-router';
import React from 'react';
import {hydrate} from 'react-dom';
import {Helmet} from 'react-helmet';
import {hot} from 'react-hot-loader/root';
import {Provider} from 'react-redux';
import YandexFavicon from 'react-yandex-favicon';

import Core from 'client/containers/core';
import {AppData} from 'client/types/app';
import store, {history} from 'client/utils/infrastructure/store';

import {Props} from './types';

import './desktop.scss';

const Bundle: Props = ({data}) => (
    <>
        <Helmet>
            <YandexFavicon lang={data.faviconLang}/>
            <html lang={data.langdetect.id}/>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
            />
        </Helmet>

        <Core appEnv={data.appEnv}/>
    </>
);

export const DesktopBundle = hot(Bundle);

export default (data: AppData) => {
    hydrate(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <DesktopBundle data={data}/>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root'),
    );
};
