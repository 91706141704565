import {Language} from 'locales';
import {handleActions} from 'redux-actions';

import {Locales, LocaleSet} from 'client/types/i18n';

export interface I18NReducer {
    locales?: Locales;
    localeSets?: {
        [k in Language]: LocaleSet;
    };
}

const defaultState: I18NReducer = {
    locales: [],
    localeSets: {
        az: {},
        be: {},
        hy: {},
        ka: {},
        kk: {},
        ky: {},
        ro: {},
        ru: {},
        uz: {},
    },
};

export const actions = {};

export default handleActions<I18NReducer>({}, defaultState);
