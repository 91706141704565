import qs from 'qs';
import {Indexed} from 'utils';

import {bound as commonActions} from 'client/actions';
import {allQuerySelector} from 'client/selectors/query';
import {getStore} from 'client/utils/infrastructure/store';

export default class RedirectsService {
    public redirectWithQuery = (url: string, params: Indexed<string> = {}) => {
        const store = getStore();
        const queries = allQuerySelector(store);
        commonActions.router.push({
            pathname: url,
            search: qs.stringify({...queries, ...params}),
        });
    };
}

export const redirectsService = new RedirectsService();
