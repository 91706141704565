import {createSelector} from 'reselect';
import {Nil} from 'utils';

import {COUNTRIES_WITH_SELFEMPLOYEE} from 'client/consts';
import {City} from 'client/types/cities';
import {CommonStore} from 'client/utils/infrastructure/store';

export const citiesSelector = createSelector(
    (state: CommonStore) => state.cities?.item?.data,
    cities => cities || [],
);

export const makeCityByIdSelector = (id: Nil<string>) => createSelector(
    (state: CommonStore) => state.cities?.item?.data,
    countries => {
        let result: Nil<City>;
        if (!id) {
            return result;
        }
        countries?.some(country => {
            country.cities.some(city => {
                if (city.id === id) {
                    result = city;
                    return true;
                }
                return false;
            });
            return result ? true : false;
        });
        return result;
    },
);

export const makeCountryByCityIdSelector = (id: Nil<string>) => createSelector(
    (state: CommonStore) => state.cities?.item?.data,
    countries => {
        let result: Nil<City>;
        if (!id) {
            return result;
        }
        const country = countries?.find(country => {
            return country.cities.some(city => {
                if (city.id === id) {
                    result = city;
                    return true;
                }
                return false;
            });
        });
        return country;
    },
);

export const makeIsSelfEmployeeCountrySelector = (id: Nil<string>) => createSelector(
    makeCountryByCityIdSelector(id),
    country =>
        COUNTRIES_WITH_SELFEMPLOYEE.includes(country?.label || ''),
);
