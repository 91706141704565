import {combineReducers} from 'redux';

import {ModalConfirmItem} from 'client/types/ui';
import {BaseEntityReducer, generateEntityReducer} from 'client/utils/infrastructure/reducers/flow';

const PREFIX = '@modalConfirm';

export type ModalConfirmReducer = BaseEntityReducer<ModalConfirmItem>;

const {item} = generateEntityReducer<ModalConfirmItem>(PREFIX);

export const actions = {
    item: item?.actions,
};

export default combineReducers<ModalConfirmReducer>({
    item: item!.reducer,
});
