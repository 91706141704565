import {memo} from 'react';
import {compose} from 'redux';

import asPage from 'client/utils/hocs/asPage';

import {PAGE_SETTINGS} from '../../consts';
import Page from './Page';
import {Props} from './types';

export default compose<Props>(
    asPage(PAGE_SETTINGS),
)(memo<Props>(Page));
