export enum ParkGroup {
    Group1 = 'group_1', // Работает с СМЗ
    Group2 = 'group_2', // Обычный парк
    Group3 = 'group_3', // Скрываем за кнопкой "Показать все парки"
    Group4 = 'group_4', // Не отображаем
}

export interface Park {
    sf_name: string;
    sf_id: string;
    address: string;
    dispatch_phone: string;
    group: ParkGroup;
    link_to_garage_ad?: string;
}

export interface ParkRequest {
    offers_rent: boolean;
    hiring_territory: string;
}

export enum RentType {
    Rent = 'rent',
    Self = 'self',
}
