import {combineReducers} from 'redux';

import {RTCEnv} from 'client/types/meta';
import {BaseEntityReducer} from 'client/utils/infrastructure/reducers/flow';

import {item as apiHiringAuthProxyItem} from './apiHiringAuthProxy';
import {item as envItem} from './env';
import {item as yandexuidItem} from './yandexuid';

type EnvReducer = BaseEntityReducer<RTCEnv>;
type ApiHiringAuthProxyReducer = BaseEntityReducer<string>;
type YandexuidReducer = BaseEntityReducer<string>;

export interface MetaReducers {
    env: EnvReducer;
    apiHiringAuthProxy: ApiHiringAuthProxyReducer;
    yandexuid: YandexuidReducer;
}

export const actions = {
    env: {
        item: envItem?.actions,
    },
    apiHiringAuthProxy: {
        item: apiHiringAuthProxyItem?.actions,
    },
};

export default combineReducers<MetaReducers>({
    env: combineReducers<EnvReducer>({
        item: envItem!.reducer,
    }),
    apiHiringAuthProxy: combineReducers<ApiHiringAuthProxyReducer>({
        item: apiHiringAuthProxyItem!.reducer,
    }),
    yandexuid: combineReducers<YandexuidReducer>({
        item: yandexuidItem!.reducer,
    }),
});
