import {handleActions} from 'redux-actions';

export interface UIReducer {
    isMobile?: boolean;
}

const defaultState: UIReducer = {
    isMobile: false,
};

export const actions = {};

export default handleActions<UIReducer>({}, defaultState);
