import {Language} from 'locales';
import {Nil} from 'utils';

import locales from 'client/locale/localesList.json';

export const checkLang = (str: Nil<string>): Language => {
    if (!str) {
        return 'ru' as Language;
    }
    if (locales.includes(str.toString())) {
        return str.toString() as Language;
    } else {
        return 'ru' as Language;
    }
};
