import {combineReducers} from 'redux';

import {Country} from 'client/types/cities';
import {BaseEntityReducer, generateEntityReducer} from 'client/utils/infrastructure/reducers/flow';

const PREFIX = '@cities';

export const {item} = generateEntityReducer<Country[]>(PREFIX);

export type CitiesReducer = BaseEntityReducer<Country[]>;

export const actions = {
    item: item?.actions,
};

export default combineReducers<CitiesReducer>({
    item: item!.reducer,
});
