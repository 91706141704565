import {createSelector} from 'reselect';

import {ParkGroup} from 'client/types/parks';
import {CommonStore} from 'client/utils/infrastructure/store';

export const parksSelector = createSelector(
    (state: CommonStore) => state.parks?.item?.data,
    parks => parks || [],
);

export const hasHiddenParksSelector = createSelector(
    parksSelector,
    parks => parks.some(park => park.group === ParkGroup.Group3),
);

export const hasSelfemployeeParksSelector = createSelector(
    parksSelector,
    parks => parks.some(park => park.group === ParkGroup.Group1),
);
