import cn from 'classnames';
import React from 'react';

import {makeBem} from 'client/utils/styles';

import {Props} from './types';

import styles from './Button.scss';

const Button: Props = ({
    children,
    icon,
    className,
    theme = 'primary',
    width,
    size = 'medium',
    variant = 'filled',
    ...props
}) => {
    return (
        <button
            {...props}
            className={cn(
                styles.button,
                !icon && styles.onlyText,
                styles[makeBem('button', theme)],
                styles[makeBem('button', null, `width_${width}`)],
                styles[makeBem('button', null, `size_${size}`)],
                styles[makeBem('button', theme, variant)],
                className,
            )}
        >
            {icon && (
                <img
                    src={icon}
                    alt="btn-icon"
                    className={cn(
                        styles.button__icon,
                        !children && styles.button__icon_solo,
                    )}
                />
            )}
            <span className={styles.button__text}>{children}</span>
        </button>
    );
};
export default Button;
