import React from 'react';

import {ROUTES} from 'client/routes';
import {redirectsService} from 'client/services/Redirects';
import {useI18N} from 'client/utils/hooks/useI18N';

import Button from '../button/components/Button';
import {Props} from './types';

import styles from './Header.scss';

const handleClick = () => {
    redirectsService.redirectWithQuery(ROUTES.CITIES.INDEX);
};

const Header: Props = ({city}) => {
    const i18n = useI18N();

    return (
        <section className={styles.wrapper}>
            <div>
                <a href="https://yandex.ru" target="_self">
                    <img src={`https://yastatic.net/q/logoaas/${i18n('YANDEX')}.svg?size=29.5&color=000`}/>
                </a>&nbsp;
                <a href="https://taxi.yandex.ru" target="_self">
                    <img src={`https://yastatic.net/q/logoaas/${i18n('TAXI')}.svg?size=29.5&color=000`}/>
                </a>
            </div>
            {city &&
                <Button theme="light" variant="outlined" onClick={handleClick} className={styles.btn}>
                    {city}
                </Button>
            }
        </section>
    );
};

export default Header;
