import {csrf, json} from './middlewares';
import HTTPTransport from './transport';

export const baseApi = new HTTPTransport('');
export const api = new HTTPTransport('/api', [json, csrf]);
export const suggestsApi = new HTTPTransport('/api/v1/suggests', [], '');
export const gamblingApi = new HTTPTransport('/api/v2/hiring-conditions', [], '');

export {
    HTTPTransport,
};
