import {EntityAPI} from 'utils';

import {Park, ParkRequest} from 'client/types/parks';
import {gamblingApi} from 'client/utils/transport';

class ParksAPI implements EntityAPI {
    public request = (params: ParkRequest) => {
        return gamblingApi.get<ParkRequest, Park[]>('/', params);
    };
}

export const apiInstance = new ParksAPI();
