import {combineReducers} from 'redux';

import {Park} from 'client/types/parks';
import {BaseEntityReducer, generateEntityReducer} from 'client/utils/infrastructure/reducers/flow';

const PREFIX = '@parks';

export const {item} = generateEntityReducer<Park[]>(PREFIX);

export type ParksReducer = BaseEntityReducer<Park[]>;

export const actions = {
    item: item?.actions,
};

export default combineReducers<ParksReducer>({
    item: item!.reducer,
});
