export const ROUTES = {
    CITIES: {
        INDEX: '/',
    },
    PARKS: {
        INDEX: '/:city/parks',
    },
    NOT_FOUND: {
        INDEX: '/not-found',
    },
};
