import {Language} from 'locales';
import {useSelector} from 'react-redux';

import {makeLocaleSetByLangSelector} from 'client/selectors/i18n';
import {langQuerySelector} from 'client/selectors/query';
import {LocaleKeys} from 'client/types/i18n';

const defaultLang: Language = 'ru';

export function useI18N() {
    const lang = useSelector(langQuerySelector);
    const defaultLocaleSet = useSelector(makeLocaleSetByLangSelector(defaultLang));
    const localeSet = useSelector(makeLocaleSetByLangSelector(lang || defaultLang));

    return (key: LocaleKeys) =>
        localeSet[key] === ''
            ? defaultLocaleSet[key]
            : localeSet[key];
}
