import {useCallback, KeyboardEvent} from 'react';

import {useEventListener, Handler} from './useEventListener';

const ESCAPE_KEY_CODE = 27;

export function useEscapeKeyListener(container: EventTarget, cb: (...args: any[]) => void) {
    const onKeyUp = useCallback(
        ((event: KeyboardEvent) => {
            if (event.keyCode === ESCAPE_KEY_CODE) {
                cb();
            }
        }) as any as Handler,
        [cb],
    );

    useEventListener('keyup', onKeyUp, true, container);
}
