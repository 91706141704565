import {createSelector} from 'reselect';

import {CommonStore} from 'client/utils/infrastructure/store';

export const apiHiringAuthProxySelector = createSelector(
    (state: CommonStore) => state.meta.apiHiringAuthProxy.item?.data,
    data => data || '',
);

export const envSelector = createSelector(
    (state: CommonStore) => state.meta.env.item?.data,
    status => status,
);

export const yandexuidSelector = createSelector(
    (state: CommonStore) => state.meta.yandexuid.item?.data,
    yandexuid => yandexuid,
);
