import {combineReducers} from 'redux';

import {CommonParams} from 'client/types/query';
import {BaseEntityReducer} from 'client/utils/infrastructure/reducers/flow';

import {item as commonItem} from './common';

type CommonReducer = BaseEntityReducer<Partial<CommonParams>>;

export interface QueryReducers {
    common: CommonReducer;
}

export const actions = {
    common: {
        item: commonItem?.actions,
    },
};

export default combineReducers<QueryReducers>({
    common: combineReducers<CommonReducer>({
        item: commonItem!.reducer,
    }),
});
