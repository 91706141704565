import '@yandex-int/error-counter/dist/interfaceWithoutRum';
import '@yandex-int/error-counter/dist/implementation';
import '@yandex-int/error-counter/dist/filters';
import '@yandex-int/error-counter/dist/logError';
import '@yandex-int/error-counter/dist/sendWithoutRum';
import '@yandex-int/error-counter/dist/logAjaxError';

import {isMobileSelector} from 'client/selectors/ui';

import {getStore} from './infrastructure/store';

type LogEnvironment = 'development' | 'testing' | 'production';

export const initErrorLogger = () => {
    const limits = {
        client: 20,
        uncaught: 20,
        script: 20,
        external: 20,
    };
    const silent = {
        client: 10,
        uncaught: 10,
        script: 10,
        external: 10,
    };
    const platform = isMobileSelector(getStore()) ? 'touch' : 'desktop';
    const env = [undefined, 'unstable'].includes(process.env.NODE_ENV)
        ? 'development'
        : process.env.NODE_ENV;

    // https://github.yandex-team.ru/rum/error-counter#настройка
    window?.Ya?.Rum?.initErrors({
        project: 'hiring-parks-landing',
        platform,
        env: env as LogEnvironment,
        limits,
        debug: false,
        silent,
    });
};
