import {parksLoader} from 'client/loaders/parks';
import {RentType} from 'client/types/parks';

export default class ParksService {
    public getParks = async (rentType: RentType, id: string) => {
        await parksLoader({
            hiring_territory: id,
            offers_rent: rentType === RentType.Rent,
        });
    };
}

export const parksService = new ParksService();
