import {RouterState} from 'connected-react-router';

import cities, {CitiesReducer} from './cities';
import i18n, {I18NReducer} from './i18n';
import meta, {MetaReducers} from './meta';
import modalConfirm, {ModalConfirmReducer} from './modalConfirm';
import page, {PageReducer} from './page';
import parks, {ParksReducer} from './parks';
import preload, {PreloadReducer} from './preload';
import query, {QueryReducers} from './query';
import router from './router';
import ui, {UIReducer} from './ui';
import user, {UserReducers} from './user';

export interface BaseStore {
    router: RouterState;
    ui: UIReducer;
    page: PageReducer;
    preload: PreloadReducer;
    meta: MetaReducers;
    modalConfirm: ModalConfirmReducer;
    cities: CitiesReducer;
    parks: ParksReducer;
    i18n: I18NReducer;
    query: QueryReducers;
    user: UserReducers;
}

export const reducers = {
    router,
    ui,
    page,
    preload,
    meta,
    modalConfirm,
    cities,
    parks,
    i18n,
    query,
    user,
};
