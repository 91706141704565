import {createSelector} from 'reselect';

import {CommonStore} from 'client/utils/infrastructure/store';

export const allQuerySelector = createSelector(
    (state: CommonStore) => state.query?.common?.item?.data,
    queries => queries,
);

export const langQuerySelector = createSelector(
    (state: CommonStore) => state.query?.common?.item?.data?.lang,
    lang => lang,
);

export const regionIdQuerySelector = createSelector(
    (state: CommonStore) => state.query?.common?.item?.data?.region_id,
    region_id => region_id,
);
