import {isEmpty} from 'lodash';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {citiesSelector} from 'client/selectors/cities';
import {regionIdQuerySelector} from 'client/selectors/query';
import {redirectsService} from 'client/services/Redirects';
import {Country} from 'client/types/cities';
import {searchBySubstr} from 'client/utils/search';

import {Props} from './types';

import styles from './Cities.scss';

const handleCityClick = (cityId: string) => () => {
    redirectsService.redirectWithQuery(`/${cityId}/parks`);
};

const Cities: Props = ({search}) => {
    const cities = useSelector(citiesSelector);
    const regionId = useSelector(regionIdQuerySelector);

    const blocks = useMemo(() => {
        return cities.reduce<Country[]>((acc, country) => {
            if (regionId && country.id !== regionId) {
                return acc;
            }
            const cities = country.cities.filter(city => searchBySubstr(city.label, search));
            if (!isEmpty(cities)) {
                acc.push({
                    ...country,
                    cities,
                });
            }
            return acc;
        }, [])
            .map((country, index) => (
                <div className={styles.block} key={index}>
                    <h3 className={styles.country}>{country.label}</h3>
                    <div className={styles.cities}>
                        {country.cities.map((city, key) => (
                            <div key={key} className={styles.city}>
                                <a onClick={handleCityClick(city.id)}>
                                    {city.label}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            ));
    }, [cities, search]);

    return (
        <>
            {blocks}
        </>
    );
};
export default Cities;
