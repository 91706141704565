import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import Button from 'client/components/button/components/Button';
import Modal from 'client/components/modal';
import {pathnameSelector} from 'client/selectors/router';
import {redirectsService} from 'client/services/Redirects';
import {RentType} from 'client/types/parks';
import {useI18N} from 'client/utils/hooks/useI18N';

import {Props} from './types';

import styles from './RentModal.scss';

const modalClassnames = {
    box: styles.box,
    body: styles.body,
    header: styles.header,
};

const RentModal: Props = ({onClose}) => {
    const path = useSelector(pathnameSelector);
    const handleConfirm = useCallback(() => {
        redirectsService.redirectWithQuery(path, {type: RentType.Self});
        onClose();
    }, [onClose, path]);
    const handleDecline = useCallback(() => {
        redirectsService.redirectWithQuery(path, {type: RentType.Rent});
        onClose();
    }, [onClose, path]);
    const i18n = useI18N();

    return (
        <Modal
            isVisible={true}
            withCloseBtn={false}
            classNames={modalClassnames}
        >
            <span className={styles.title}>{i18n('RENT_QUESTION')}</span>
            <div className={styles.btnWrapper}>
                <Button className={styles.btn} theme="light" variant="outlined" onClick={handleConfirm}>
                    {i18n('YES')}
                </Button>
                <Button className={styles.btn} theme="light" variant="outlined" onClick={handleDecline}>
                    {i18n('NO')}
                </Button>
            </div>
        </Modal>
    );
};

export default RentModal;
