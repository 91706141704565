import {EntityAPI} from 'utils';

import {ParkCities} from 'client/types/suggests';
import {suggestsApi} from 'client/utils/transport';

class CitiesAPI implements EntityAPI {
    public request = () => {
        return suggestsApi.get<void, ParkCities>('/landings/paid-acquisition/cities');
    };
}

export const apiInstance = new CitiesAPI();
