import React from 'react';

import {bound as commonActions} from 'client/actions';
import Button, {Group, ButtonProps} from 'client/components/button';
import Modal from 'client/components/modal';

import {Props} from './types';

import styles from './ModalConfirm.scss';

function handleCloseModal() {
    commonActions.modalConfirm.item.success({visible: false});
}

const ModalConfirm: Props = ({handleSubmit, content}) => {
    return (
        <Modal
            title="Вы хотите подтвердить действие?"
            handleClose={handleCloseModal}
            isVisible
        >
            <div>
                {content}
            </div>

            <Group className={styles.buttonWrapper}>
                <Button theme="primary" onClick={handleSubmit as ButtonProps['onClick']}>
                    Да
                </Button>
                <Button theme="secondary" onClick={handleCloseModal}>
                    Нет
                </Button>
            </Group>
        </Modal>
    );
};

export default ModalConfirm;
