import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {Switch} from 'react-router';

import Layout from 'client/components/layout';
import ModalConfirm from 'client/components/modal-confirm';
import YaMetrika from 'client/components/ya-metrika';
import Cities from 'client/containers/cities/routing';
import NotFound from 'client/containers/not-found/routing';
import Parks from 'client/containers/parks/routing';
import {isVisibleModalConfirmSelector} from 'client/selectors/modalConfirm';

const Wrapper = () => {
    const visibleModalConfirmItem = useSelector(isVisibleModalConfirmSelector);

    return (
        <>
            <Helmet>
                <title>Выбор парков</title>
                <meta name="title" content="parks-landing"/>
            </Helmet>

            <Layout>
                <Switch>
                    {Cities}
                    {Parks}
                    {NotFound}
                </Switch>
            </Layout>

            {visibleModalConfirmItem.visible && (
                <ModalConfirm
                    handleSubmit={visibleModalConfirmItem?.handleSubmit}
                />
            )}

            <YaMetrika/>
        </>
    );
};
export default Wrapper;
