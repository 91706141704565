export default {
    __PROD__: 'production',
    __TEST__: 'testing',
    __DEV__: 'development',

    BASE_PATH: '/',

    api: {
        host: {
            production: 'https://hiring-authproxy.taxi.yandex.ru',
            tst: 'https://hiring-authproxy.taxi.tst.yandex.ru',
        },
    },
};
