import {useEffect, useRef} from 'react';
import {Nullable} from 'utils';

export type Handler = (event: Event) => void;

export function useEventListener(
    eventName: string,
    handler: Handler,
    useCapture = false,
    container: Nullable<EventTarget>,
) {
    const savedHandler = useRef<Handler>();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            if (!container) {
                return;
            }

            const eventListener = (event: Event) => savedHandler.current?.(event);

            container.addEventListener(eventName, eventListener, useCapture);

            // cleanup
            return () => {
                container.removeEventListener(eventName, eventListener, useCapture);
            };
        },
        // Re-run if changes
        [eventName, container],
    );
}
